enum Modules {
    // action types
    NewProductEntry = "NewProductEntry"
    , TableProductEntry = "TableProductEntry"
    , TableNote = "TableNote"
    , TableSettlement = "TableSettlement"
    , NewSettlement = "NewSettlement"
    , RolesModule = "RolesModule"
    , DashboardModule = "DashboardModule"
    , AccountModule = "AccountModule"
    , ClientTableModule = "ClientTableModule"
    , NewClientModule = "NewClientModule"
    , NewBill = "NewBill"
    , NewSaleModule = "NewSaleModule"
    , TableSalesToBill = "TableSalesToBill"
    , TableBillCreated = "TableBillCreated"
    , NewDeliveryModule = "NewDeliveryModule"
    , DeliveryTableModule = "DeliveryTableModule"
    , TableBillProducts = "TableBillProducts"
    , SaleTypeModule = "SaleTypeModule"
    , TaxesModule = "TaxesModule"
    , SaleTableQuoteModule = "SaleTableQuoteModule"
    , TypeCfdiTableModule = "TypeCfdiTableModule"
    , NewTypeCfdiModule = "NewTypeCfdiModule"
    , NewWayToPayModule = "NewWayToPayModule"
    , WayToPayTableModule = "WayToPayTableModule"
    , PaymentTypeTableModule = "PaymentTypeTableModule"
    , NewPaymentTypeModule = "NewPaymentTypeModule"
    , CountryTableModule = "CountryTableModule"
    , NewCountryModule = "NewCountryModule"
    , StateTableModule = "StateTableModule"
    , NewStateModule = "NewStateModule"
    , PopulationTableModule = "PopulationTableModule"
    , NewPopulationModule = "NewPopulationModule"
    , NewRegimeModule = "NewRegimeModule"
    , RegimeTableModule = "RegimeTableModule"
    , NewSaleReturnModule = "NewSaleReturnModule"
    , TableSaleReturnModule = "TableSaleReturnModule"
    , CashModule = "CashModule"
    , CashiersModule = "CashiersModule"
    , TableCashiersModule = "TableCashiersModule"
    , TableCashRegistersModule = "TableCashRegistersModule"
    , CashRegisterModule = "CashRegisterModule"
    , NewArticle = "NewArticle"
    , SaleTableModule = "SaleTableModule"
    , TableReceptionModule = "TableReceptionModule"
    , TableReceptionDetailsModule = "TableReceptionDetailsModule"
    , TableInputsModule = "TableInputsModule"
    , TableTransfersModule = "TableTransfersModule"
    , TableTransferDetailsModule = "TableTransferDetailsModule"
    , NewTransfer = "NewTransfer"
    , NewTransferDetail = "NewTransferDetail"
    , TableMovementTypeModule = "TableMovementTypeModule"
    , NewMovementTypeModule = "NewMovementTypeModule"
    , TableWareHouseAdminModule = "TableWareHouseAdminModule"
}

export default Modules;