import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { translate } from '@/core/plugins/i18n';
import DeliveryFilter from '@/models/delivery/DeliveryFilter';
import DeliveryRow from '@/models/delivery/DeliveryRows';
import DeliveryToPlan from '@/models/delivery/DeliveryToPlan';
import NewDelivery from '@/models/delivery/NewDelivery';
import PaginationResponse from '@/models/general/PaginationResponse';
import TablePaginationRequest from '@/models/general/TablePaginationRequest';
import OptionSelect from '@/models/shared/OptionSelect';
import { AxiosResponse } from 'axios';
import ApiService from '../ApiService';
import GenericCrudService from "../general/GenericCrudService";
import SwalMessageService from '../SwalMessageService';
import DeliveryTableService from './interfaces/DeliveryTableService';

export default class DeliveryService 
    extends GenericCrudService<NewDelivery, DeliveryRow> 
    implements DeliveryTableService {

    constructor(){
        super("/SaleDelivery", new SwalMessageService() );
    }
    
    /**
     * Recupera información de la venta para el grid
     * @param request filtros para recuperar ventas
     * @returns listado de ventas
     */
    async getTableInfo(request: DeliveryFilter): Promise<AxiosResponse<DeliveryRow[]>> {
        return  ApiService.get<DeliveryRow[]>(this.apiController, 
            `SaleDeliveryByDate?`
            + `beginAt=${request.beginAt}`
            + `&endAt=${request.endAt}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchSalesToPlanOptions(filterName: string): Promise<AxiosResponse<DeliveryToPlan[]>> {
        return  ApiService.get<DeliveryToPlan[]>(this.apiController, 
            `SaleDeliveryToPlan?`
            + `criteria=${filterName}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }


    async searchAgentsOptions(filterName: string): Promise<AxiosResponse<OptionSelect[]>> {
        return  ApiService.get<OptionSelect[]>('/Users', 
            `GetAgentsList?`
            + `criteria=${filterName}`
        ).then( resp => {
            return resp;
        }).catch(error => {
            //getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async updateDelivery(id: string, newInfo: NewDelivery): Promise<AxiosResponse<boolean>> {
        return  ApiService.update<boolean>(this.apiController 
            , id
            , newInfo
        ).then( resp => {
            debugger
            if(resp.status >= 204 && resp.status < 500){
                this.messageService.success(translate('MESSAGES.SAVE_CORRECT'));
            }

            return {...resp, data: (resp.status >= 200 && resp.status >= 204 && resp.status < 300)};
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

}