
import { computed, defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import { Field, Form } from "vee-validate";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import * as Validations from "yup";
import { getModule } from "vuex-module-decorators";
import NewDeliveryModule from "@/store/modules/deliverysale/modules/new-delivery";

interface NewAddressData {
  eventName: string;
  eventDescription: string;
  eventLocation: string;
  allDay: boolean;
  eventStartDate: string;
  eventEndDate: string;
}

export default defineComponent({
  name: "new-event-modal",
  components: {
    SelectFilterRemote
   ,Field
   ,Form
   ,DynamicContentModal
  },
  setup(props, { emit }) {
    const value1 = ref(new Date(2016, 9, 10, 18, 30))
    // const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
   
    const loading = ref<boolean>(false);
    const formAdd = ref();
    const moduleDelivery = getModule(NewDeliveryModule);



    const lstPedidos = computed(() => {
            return [
                {
                id: "VXX123",
                name: "VXX123"
                },
                {
                id: "VXX124",
                name: "VXX124"
                },
                {
                id: "VXX125",
                name: "VXX125"
                },
                {
                id: "VXX126",
                name: "VXX126"
                }]
    });

    const agenteOptions = computed(() => {
            return [
                {
                id: "1234",
                name: "Juan Alberto Peréz"
                },
                {
                id: "1235",
                name: "Jaime Tuz"
                },
                {
                id: "1236",
                name: "Pedro Andres Hua"
                },
                {
                id: "1237",
                name: "Yeremi May"
                }]
    });

    const validationSchema = Validations.object().shape({
              salesId: Validations.string().nullable().required().label("salesId"),
              agenteId: Validations.string().nullable().required().label("agenteId"),
              dateDeliveries: Validations.string().nullable().required().label("dateDeliveries"),
              timeDeliveries: Validations.string().nullable().required().label("timeDeliveries")
    });

    const targetData = ref<NewAddressData>({
      eventName: "",
      eventDescription: "",
      eventLocation: "",
      allDay: true,
      eventStartDate: "",
      eventEndDate: "",
    });

    const rules = ref({
      eventName: [
        {
          required: true,
          message: "Please input event name",
          trigger: "blur",
        },
      ],
    });

    const makeRange = (start: number, end: number) => {
    const result: number[] = []
    for (let i = start; i <= end; i++) {
        result.push(i)
    }
    return result
    }

    const disabledHours = () => {
    return makeRange(0, 16).concat(makeRange(19, 23))
    }
    const disabledMinutes = (hour: number) => {
    if (hour === 17) {
        return makeRange(0, 29)
    }
    if (hour === 18) {
        return makeRange(31, 59)
    }
    }
    const disabledSeconds = (hour: number, minute: number) => {
    if (hour === 18 && minute === 30) {
        return makeRange(1, 59)
    }
    }

    const searchSales = (filterName: string) => {
      moduleDelivery.GET_DELIVERY_PLAN(filterName);
    }

    const searchAgent = (filterName: string) => {
      moduleDelivery.GET_AGENTS(filterName);
    }

    const ItemsAgents = computed(() => moduleDelivery.getAgentsOptions);
    const ItemsSales = computed(() => moduleDelivery.getSalesToPlanOptions);
    const loadingSalesCombo = computed(() => moduleDelivery.isLoadingSales);
    const loadingAgentsCombo = computed(() => moduleDelivery.isLoadingAgents);


    const submitForm = (data) => {
        // if (!formRef.value) {
        //   return;
        // }

       
        // formRef.value.resetForm();
        formAdd.value.resetForm();

        emit('newdeliverie', data);
    }

    return {
      newTargetModalRef,
      loading,
      targetData,
      rules,
      validationSchema,
      value1,
      formAdd,
      submitForm,
      disabledHours,
      disabledMinutes,
      disabledSeconds,
      lstPedidos,
      agenteOptions

      , searchSales
      , searchAgent

      , loadingSalesCombo
      , loadingAgentsCombo
      , ItemsAgents
      , ItemsSales
    };
  },
});
