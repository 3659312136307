
import { defineComponent, ref, reactive, computed, onMounted, nextTick } from "vue";
import { translate } from "@/core/plugins/i18n";
import FullCalendar, { EventClickArg } from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import TableDeliveryModule from "@/store/modules/deliverysale/modules/table";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import NewEventModal from "./components/AddDeliveries.vue"
import moment from "moment";
import { getModule } from "vuex-module-decorators";
import { Field, Form } from "vee-validate";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import * as Validations from "yup";
import NewDeliveryModule from "@/store/modules/deliverysale/modules/new-delivery";
import { formatToCalendar } from "@/common/formaterToCalendar";
import _ from "lodash";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

const todayDate = moment().startOf("day");
const TODAY = todayDate.format("YYYY-MM-DD");

interface Event {
  id: number;
  title: string;
  start: Date;
  end: Date;
}

export default defineComponent({
  name: "calendar-app-1",
  components: {
    FullCalendar,
    NewEventModal,
    SelectFilterRemote
    ,Field
    ,Form
  },
  setup() {
  const calendar = ref<InstanceType<typeof FullCalendar>>()
  const moduleNewDelivery = getModule(NewDeliveryModule);
  const moduleDelivery = getModule(TableDeliveryModule);
  const formAdd = ref();
  const events = ref<Event[]>([]);

  const validationSchema = Validations.object().shape({
      salesId: Validations.string().nullable().required().label("salesId"),
      agenteId: Validations.string().nullable().required().label("agenteId"),
      dateDeliveries: Validations.string().nullable().required().label("dateDeliveries"),
      timeDeliveries: Validations.string().nullable().required().label("timeDeliveries"),
      receiver: Validations.string().nullable().default(null).required().label("receiver"),
      observation: Validations.string().nullable().default(null).required().label("observation")
  });

  const currentDateStart = ref('');
  const currentDateEnd = ref('');


  const onCalendarReady = () => {
      const calendarApi = calendar.value?.getApi();

      if (calendarApi) {
        calendarApi.on('datesSet', (info) => {
          
          if(currentDateStart.value != info.start.toISOString() &&
              currentDateEnd.value != info.end.toISOString()){

              currentDateStart.value = info.start.toISOString();
              currentDateEnd.value = info.end.toISOString();

              moduleDelivery.GET_DELIVERY({
                  endAt: info.end.toISOString(),
                  beginAt: info.start.toISOString()
              }).then((response) => {
                  if(response.length > 0){
                    const rows = formatToCalendar(moduleDelivery.records);
                    calendarOptions.value.events = rows;

                  }
              });
             }
        });
      }
    };

    const submitForm = (data) => {
      console.log(data);
      var fecha = new Date(data.dateDeliveries + "T" + data.timeDeliveries + "z");
     
        moduleNewDelivery.SAVE_NEW_DELIVERY({
          id: data.salesId,
          observation: data.observation,
          receiver: data.receiver,
          saleDeliveryPlanAt: fecha.toISOString(),
          userAgentId: data.agenteId
        }).then(() => {
          currentDateEnd.value = '';
          currentDateStart.value = '';

          calendarOptions.value.events = [];

        });

        formAdd.value.resetForm();
    }

    const newEvent = (clickInfo: EventClickArg) => {
      console.log(clickInfo);
      // const modal = new Modal(document.getElementById("AddDeliveriesrequest"));
      // modal.show();
    };

    const newEvent2 = (clickInfo: EventClickArg) => {
      console.log(clickInfo);

    };

    const INITIAL_EVENTS = [
      {
        id: 1,
        title: 'Pedido: YYY123 ',
        start: TODAY + "T09:00:00",
        end: TODAY + "T10:20:00",
      }];

    const calendarOptions = ref({
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      initialView: "timeGridDay",
      initialDate: TODAY,
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      selectMirror: true,
      locale: 'es',
      views: {
        dayGridMonth: { buttonText: "Mes" },
        timeGridWeek: { buttonText: "Semana" },
        timeGridDay: { buttonText: "Dia" },
      },

      editable: true,
      dayMaxEvents: true, // allow "more" link when too many events
      events: INITIAL_EVENTS,
      datesSet: onCalendarReady,
      dateClick: newEvent
    });
    
    //Metodos
    const changeSales = _.throttle((idSelected: string) => {
        const item = ItemsSales.value.find(x => x.id == idSelected);

        if(item){
          formAdd.value.setValues({
                   salesId: item.id,
                   receiver: item.receiver,
                   observation: item.observation
                });
        }
    });

    const searchSales = (filterName: string) => {
      moduleNewDelivery.GET_DELIVERY_PLAN(filterName);
    }

    const searchAgent = (filterName: string) => {
      moduleNewDelivery.GET_AGENTS(filterName);
    }

    //VARIABLES COMPUTADAS
    const ItemsAgents = computed(() => moduleNewDelivery.getAgentsOptions);
    const ItemsSales = computed(() => moduleNewDelivery.getSalesToPlanOptions);
    const loadingSalesCombo = computed(() => moduleNewDelivery.isLoadingSales);
    const loadingAgentsCombo = computed(() => moduleNewDelivery.isLoadingAgents);

    onMounted(() => {
      setCurrentPageBreadcrumbs(translate('BREADCRUMB.DELIVERYSALE'), [translate('BREADCRUMB.MODULES')]);
      nextTick(() => {
            calendarOptions.value.events = [];
            // onCalendarReady();
      });
    });

    return {
      calendarOptions,
      validationSchema,
      calendar,
      

      ItemsAgents
      , formAdd
      , ItemsSales
      , loadingSalesCombo
      , loadingAgentsCombo
      , events
      , changeSales

      , submitForm
      , searchSales
      , searchAgent
      , onCalendarReady
      , newEvent2

      , newEvent
    };
  },
});
